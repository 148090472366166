@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url("./../fonts/Poppins-Light.ttf");
}

:root {
	--background_color: #4c4c4c !important;
  --primary_color: #a12935;
  --secondary_color: #4c4c4c;
  --menu_item_gray_color: #eef2f6;

  --font: WorkSans, sans-serif;
}

body {
  font-family: var(--font) !important;
}

img.flag {
	width: 25px;
	height: auto;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24,
}

.green {
  color: #008000;
}

.loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background_color) !important;
}

.loader img {
	max-height: 100px;
	display: flex;
}

iframe.login {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.MuiListItemAvatar-root {
  text-align: center !important;
}


.loading {
  width: 100%;
  height: 25px;
  text-align: center;
}

iframe.embebbed {
  width: 100%;
  border: 0;
  height: calc(100vh - 251px)
}

/* Profile menu */
.profile-menu {
  background-color: var(--menu_item_gray_color) !important;
  border: none !important;
}

.profile-menu .MuiChip-label {
  color: var(--primary_color) !important;
}

.profile-menu[aria-controls="menu-list-grow"], 
.profile-menu:hover {
  border: none !important;
  background: var(--primary_color) !important;
}

.MuiListItemButton-gutters:hover {
    background-color: var(--primary_color) !important;
}

.MuiListItemButton-gutters:hover .MuiListItemIcon-root {
  color: white !important;
}

.MuiListItemButton-gutters:hover p {
  color: white !important;
}

/* Locale menu */
.MuiAvatar-colorDefault:hover {
  background: var(--primary_color) !important;
  color: white !important;
}

.MuiAvatar-colorDefault:hover .locale-icon {
  color: white !important;
  stroke: none !important;
}

.MuiAvatar-colorDefault[aria-controls="menu-list-grow"], .css-1mjiab4-MuiAvatar-root:hover {
  background: var(--primary_color) !important;
  color: white !important;
}

.locale-list div:hover {
  background: var(--menu_item_gray_color) !important;
}

.locale-list div:hover span {
  color: var(--primary_color) !important;
}

/*Menu toogle*/
.menu-toggle {
  background: var(--primary_color) !important;
  color: white !important;
}

.menu-toggle:hover {
  background: var(--secondary_color) !important;
}

.menu-toggle {
  border-radius: 12px;
}

/*Menu items*/
.menu-item:hover {
  background-color: var(--primary_color) !important;
}

.menu-item:hover .MuiListItemIcon-root {
  color: white !important;
}

.menu-item:hover p {
  color: white !important;
}

.menu-item.Mui-selected {
  color: var(--primary_color) !important;
  background-color: var(--menu_item_gray_color) !important;
}

.menu-item.Mui-selected .MuiListItemIcon-root {
  color: var(--primary_color) !important;
}

table tr:nth-child(even) {
  background-color: #f7f7f7;
}

table tbody tr {
  transition: all ease 0.25s;
}

.link {
  cursor: pointer;
}

table tbody tr:hover {
  background-color: #f7f7f7;
}

table tr:nth-child(even):hover {
  background-color: #f2f2f2;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-input {
  background: white !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 0 !important;
}

.MuiFormLabel-asterisk {
  color: #f03434;
}

.form-title {
  text-align: center;
  margin-top: 0;
  padding: 0;
  margin-bottom: 35px;
  color: var(--primary_color);
  font-size: 25px;
  font-weight: normal;
}

.text-primary {
  color: var(--primary_color);
}

.text-transparent {
  opacity: 0 !important;
}

.text-success {
  color: #39bb6c;
}

.text-danger {
  color: #ca4040;
}

.MuiButton-sizeSmall {
  padding: 0px 10px;
}

.MuiButton-containedPrimary {
  background-color: var(--primary_color);
}

.MuiButton-containedPrimary:hover {
  background-color: var(--secondary_color);
}

.MuiButton-outlinedPrimary {
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
}

.MuiButton-outlinedPrimary:hover {
  border: 1px solid var(--secondary_color);
  color: var(--secondary_color);
  background: none;
}

label span {
  background: white;
}

.link.back {
  float: left;
  margin-right: 3px;
  margin-top: -1px;
}

.notification-success {
  background-color: #07bc0c;
}

.MuiIconButton-colorPrimary {
  color: var(--primary_color);
}

table tbody tr .MuiStack-root {
  justify-content: right;
  transition: all 0.25s ease;
}

.MuiTableCell-root {
  padding: 9px 16px;
}

.MuiAutocomplete-inputRoot {
  background: none !important;
}

.MuiAutocomplete-tag {
  margin: 0 !important;
}

.subtitle {
  font-style: italic;
  color: var(--primary_color);
  opacity: 0.75;
  font-size: 12px;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 0;
}

.MuiChip-filled.Mui-disabled {
  opacity: 1;
}

img.table-person-profile {
  width: 25px;
  height: 25px;

  min-width: 25px;
  min-height: 25px;

  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

div.table-person-profile {
  width: 25px;
  height: 25px;

  min-width: 25px;
  min-height: 25px;

  border-radius: 50%;
  float: left;
  margin-right: 5px;
  background-size: cover;
}

.text-center {
  text-align: center;
}

img.person-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.25s ease;
}

img.person-profile:hover {
  transform: scale(1.05);
}

div.person-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.25s ease;
  margin: auto;
  left: 0;
  right: 0;
  background-size: cover;
}

div.person-profile:hover {
  transform: scale(1.05);
}

.table-profile-container {
  display: flex;
  /*flex-wrap: nowrap; */
  align-items: center
}

.f-right {
  float: right;
}

label.error {
  color: #f03434;
  display: block;
  font-size: 12px;
}

#profile-section {
  position: relative;
}

#assistedauth-image {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 21px;
}

#status-iframe {
  width: 450px;
  height: 41px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  overflow: hidden;
}

.MuiTypography-root {
  font-family: var(--font) !important;
}

#profile-section .MuiAvatar-root {
  background: none !important;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 1;
}

.error {
  text-align: center;
}

.error .content {
  position: relative;
  z-index: 1000;
}

.error h1 {
  font-size: 85px !important;
  margin-bottom: 5px;
}

.error img {
  height: 125px;
  width: auto;
  margin: auto;
  left: 0;
  right: 0;
  margin-bottom: 25px;
}

.error h3 {
  margin-bottom: 0;
}

.error h5 {
  font-size: 15px;
}

.error a, .error button {
  width: 200px;
  margin: 5px;
  margin-top: 30px;
}

.error .block-space {
  margin-bottom: 150px;
}

.error svg {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

a {
  text-decoration: none !important;
}
