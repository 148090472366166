#ai-agent {
  position: fixed;
  right: -400px;
  top: 0;
  bottom: 0;
  transition: all ease 0.25s;
  width: 400px;
  background: white;
  z-index: 10000;
  border: 1px solid var(--menu_item_gray_color);
}

#ai-agent-title {
	height: 45px;
	position: relative;
	display: flex;
	align-items: center;
}

#ai-agent-title h1 {
  margin: 0;
  font-weight: normal;
  font-size: 23px;
  margin-left: 10px;
}

#ai-agent-close {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #364152;
  display: none;
}

#ai-agent-iframe {
	height: calc(100vh - 45px);
	border: 0;
	border-top: 1px solid var(--menu_item_gray_color);
  width: 100%;
}

#ai-agent.oppened {
  right: 0 !important;
}

#ai-agent.oppened #ai-agent-close {
  display: block;
}

.ai-agent-action-link {
  display: none;
}
